/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql } from 'gatsby';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/page-solutions/banner';
import BeardCrumbs from '../page-components/breadCrumbs';

// Assests
import SEPERATOR from '../images/icons/seperator-icon.svg';
import BANNER_IMG from '../images/index/banner-section/banner.jpg';

// Styles
import '../page-styles/presentation.scss';
import '../page-styles/iso-presentation.scss';

/* -------------------------------------------------------------------------- */
/*                            Presentation Page                               */
/* -------------------------------------------------------------------------- */

function IsoPresentationPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Présentation';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <BannerSection bannerImage={BANNER_IMG}>
        <h1>DECLARATION DE LA POLITIQUE QUALITE</h1>
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="" fluid>
          <BeardCrumbs
            path="iso-presentation"
            titleBreadcrumbs="Déclaration de la politique Qualité"
          />
        </Container>
      </Visible>
      <Container className="presentation-page fluid" fluid>
        <Container className="presentation-page-content fluid" fluid>
          <p className="description w-90">
            La société
            <span className="title"> Luxor Technologie & Trade </span>
            a décidé de mettre ses clients et les parties intéressées au cœur de
            ses préoccupations car ils représentent la garantie de sa pérennité
            et sa prospérité.
            <br />
            Pour les satisfaire, la société est engagée dans un processus
            d’amélioration continue respectant les obligations légales et
            réglementaires, ainsi que ses engagements contractuels.
            <br />
            <br />
            Cette démarche d’amélioration continue va permettre à la société de
            lever les défis présents et futurs devant la concurrence qui devient
            de plus en plus rude à l&apos;échelle nationale et internationale
            <br />
            <br />
            Et pour la concrétiser la Direction Générale a décidé de mettre en
            place un système de management de la qualité conformément à la norme
            ISO 9001.
          </p>
        </Container>
        <p className="products-heading w-80">
          A travers son système de management, Luxor Technology & Trade
          concentre ses efforts pour :
        </p>
        <ul className="iso-lists">
          <Container className="single-list-iso flex items-center fluid">
            <img src={SEPERATOR} alt="decoratif seperator" />
            <li>Accroitre la satisfaction des clients.</li>
          </Container>
          <Container className="single-list-iso flex items-center fluid">
            <img src={SEPERATOR} alt="decoratif seperator" />
            <li>Améliorer ses performances.</li>
          </Container>
          <Container className="single-list-iso flex items-center fluid">
            <img src={SEPERATOR} alt="decoratif seperator" />
            <li>Respecter les exigences applicables.</li>
          </Container>
          <Container className="single-list-iso flex items-center fluid">
            <img src={SEPERATOR} alt="decoratif seperator" />
            <li>Développer les compétences du personnel.</li>
          </Container>
        </ul>
        <p className="description w-90">
          Pour y parvenir la Direction Générale s&apos;engage à allouer les
          ressources nécessaires à la mise en œuvre et à l&apos;amélioration
          continue du Système de Management de la Qualité. A cet effet,
          j&apos;appelle tout le personnel à n&apos;épargner aucun effort afin
          de réussir notre démarche qualité.
        </p>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IsoPresentationPage;
